import React from 'react';
import PropTypes from 'prop-types';
// import { useMediaQuery } from '@react-hook/media-query';

import { SmartLink } from '../SmartLink';

import style from './PortfolioCard.module.scss';

const PortfolioCard = ({ name, description, icon, cta }) => {
    // const isMobile = useMediaQuery('only screen and (max-width: 599px)');
    return (
        <div className={style.el}>
            <div className={style.imageWrap}>
                <div className={style.image} style={{ backgroundImage: `url(${icon?.url})` }} />
            </div>
            <div className={style.overlay}>
                <div className={style.overlayWrap}>
                    <div>
                        <div className={style.companyName}>
                            <h3>{name}</h3>
                        </div>
                        <div className={style.companyDesc}>
                            <p>{description}</p>
                        </div>
                    </div>
                    <SmartLink href={cta?.link.url} hasArrow className={[style.cta, 'arrow-link'].join(' ')}>
                        {cta?.text}
                    </SmartLink>
                </div>
            </div>
        </div>
    );
};

PortfolioCard.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.object,
    cta: PropTypes.object,
};

export default PortfolioCard;
